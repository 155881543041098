import React, { useEffect } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";

export const useAdjustments = (familyId, fromMonthStr, toMonthStr) => {
	const [loading, setLoading] = React.useState(false);
	const [adjustments, setAdjustments] = React.useState([]);

	useEffect(() => {
		setLoading(true);

		const collectionRef = firebase.firestore().collection("adjustments");

		let useFromMonthStr = fromMonthStr;
		if (!fromMonthStr && !toMonthStr) {
			// default is all future diensten
			useFromMonthStr = moment().format("YYYY_MM");
		}

		let query;
		if (useFromMonthStr) {
			const start = moment(useFromMonthStr, "YYYY_MM").startOf("month").format("YYYY-MM-DD");
			query = collectionRef.where("date", ">=", start);
		}

		if (toMonthStr) {
			const end = moment(toMonthStr, "YYYY_MM").endOf("month").format("YYYY-MM-DD");
			query = query ? query.where("date", "<=", end) : collectionRef.where("date", "<=", end);
		}

		if (familyId) {
			query = query
				? query.where("familyId", "==", familyId)
				: collectionRef.where("familyId", "==", familyId);
		}

		return query.onSnapshot((querySnapshot) => {
			let data = [];
			querySnapshot.forEach((doc) => {
				data.push({ ...doc.data(), id: doc.id });
			});

			setAdjustments(data);
			setLoading(false);
		});
	}, [familyId, fromMonthStr, toMonthStr]);

	return { loading, adjustments };
};
