import React, { useState } from "react";
import { Icon, Button, Modal, Table } from "semantic-ui-react";
import { useActualDiensten } from "../../../hooks/useActualDiensten";
import moment from "moment";
import { useNanniesById } from "../../../hooks/useNannies";
import {
	getDienstNannyName,
	getDienstSourceLabel,
	getDienstStatusLabelComponent
} from "../../../constants";

const fromStr = moment().subtract(3, "month").format("YYYY_MM");
const toStr = moment().add(3, "month").format("YYYY_MM");

const OtherDienstenModal = ({ diensten, open, onOpen, onClose, familyName }) => {
	const nanniesById = useNanniesById();

	return (
		<Modal open={open} onOpen={onOpen} onClose={onClose}>
			<Modal.Header>Andere diensten voor "{familyName}"</Modal.Header>
			<Modal.Content>
				<Table compact celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Datum</Table.HeaderCell>
							<Table.HeaderCell>Dag van de week</Table.HeaderCell>
							<Table.HeaderCell>Nanny</Table.HeaderCell>
							<Table.HeaderCell>Type</Table.HeaderCell>
							<Table.HeaderCell>Status</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{diensten?.map((dienst) => (
							<Table.Row key={dienst.id}>
								<Table.Cell>{dienst.displayDate}</Table.Cell>
								<Table.Cell>
									{moment(dienst.date, "YYYY-MM-DD").format("dddd")}
								</Table.Cell>
								<Table.Cell>{getDienstNannyName(dienst, nanniesById)}</Table.Cell>
								<Table.Cell>{getDienstSourceLabel(dienst)}</Table.Cell>
								<Table.Cell>
									{getDienstStatusLabelComponent(dienst.status)}
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>Terug</Button>
			</Modal.Actions>
		</Modal>
	);
};

export const ShowOtherDienstenButton = ({ familyId, family }) => {
	const [open, setOpen] = useState(false);
	const { loading, actualDiensten } = useActualDiensten({
		familyId,
		fromMonthStr: fromStr,
		toMonthStr: toStr,
		includeReplaced: false,
		includeDiensten: true,
		includeSubscriptionDiensten: true,
		onlyLocalChanges: true,
		includeCancelled: true
	});

	return (
		<>
			<OtherDienstenModal
				open={open}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				diensten={actualDiensten}
				familyName={family?.name}
			/>
			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button
					icon
					labelPosition="right"
					onClick={() => setOpen(true)}
					compact
					loading={loading}
				>
					Andere diensten voor gezin
					<Icon name="long arrow alternate right" />
				</Button>
			</div>
		</>
	);
};
