import React from "react";
import { Input, Table, Icon, Button } from "semantic-ui-react";

import styles from "./index.module.css";
import { useFamilies, useFamiliesById } from "../../hooks/useFamilies";
import { useKnownNannies } from "../../hooks/useKnownNannies";

import { columns as allColumns } from "./columns";
import { useNanniesById } from "../../hooks/useNannies";

export const DienstenTable = ({
	diensten,
	reactions,
	openAddEditModal,
	openDeleteModal,
	columns = allColumns,
	deletable = true
}) => {
	const [sort, setSort] = React.useState("lastmodified");
	const [asc, setAsc] = React.useState(false);
	const [search, setSearch] = React.useState({});
	const familiesById = useFamiliesById();
	const knownNanniesPerFamily = useKnownNannies();
	const nanniesById = useNanniesById();

	const onSortClick = (newSort) => () => {
		if (sort === newSort) {
			setAsc(!asc);
		} else {
			setSort(newSort);
			setAsc(true);
		}
	};

	const handleSearchChange =
		(columnName) =>
		(e, { value }) =>
			setSearch((oldSearch) => ({ ...oldSearch, [columnName]: value }));

	// Filter on search text
	const filteredDiensten = React.useMemo(() => {
		let filteredDiensten = [...diensten];
		for (let c = 0; c < columns.length; c++) {
			const column = columns[c];
			const colSearch = search[column.name];
			if (!colSearch) continue;

			filteredDiensten = filteredDiensten.filter((dienst) => {
				if (column.filter)
					return column.filter(
						colSearch,
						dienst,
						familiesById,
						knownNanniesPerFamily,
						reactions
					);

				const content = column.getDisplayContent(
					dienst,
					familiesById,
					knownNanniesPerFamily,
					reactions,
					nanniesById
				);

				if (typeof content !== "string") return false;
				if (typeof colSearch !== "string") return false;
				return content.toUpperCase().includes(colSearch.toUpperCase());
			});
		}

		return filteredDiensten;
	}, [columns, diensten, familiesById, knownNanniesPerFamily, nanniesById, reactions, search]);

	// Sort diensten on selected column(s)
	const sortedDiensten = React.useMemo(() => {
		const sortColumnIdx = columns.map((column) => column.name).indexOf(sort);
		if (sortColumnIdx < 0) return filteredDiensten;

		const sortColumn = columns[sortColumnIdx];
		let sorted = filteredDiensten.sort((a, b) => {
			let contentA;
			let contentB;
			if (sortColumn.getSortLabel) {
				contentA = sortColumn.getSortLabel(
					a,
					familiesById,
					knownNanniesPerFamily,
					reactions,
					nanniesById
				);
				contentB = sortColumn.getSortLabel(
					b,
					familiesById,
					knownNanniesPerFamily,
					reactions,
					nanniesById
				);
			} else {
				contentA = sortColumn.getDisplayContent(
					a,
					familiesById,
					knownNanniesPerFamily,
					reactions,
					nanniesById
				);
				contentB = sortColumn.getDisplayContent(
					b,
					familiesById,
					knownNanniesPerFamily,
					reactions,
					nanniesById
				);
			}

			if (contentA < contentB) return -1;
			if (contentB < contentA) return 1;
			return 0;
		});

		if (!asc) sorted.reverse();
		return sorted;
	}, [
		columns,
		sort,
		filteredDiensten,
		asc,
		familiesById,
		knownNanniesPerFamily,
		reactions,
		nanniesById
	]);

	const copyVisibleDiensten = () => {
		const visibleDiensten = sortedDiensten.map(
			(dienst) => `${dienst.displayDate} - ${dienst.startTime} tot ${dienst.endTime}`
		);
		navigator.clipboard.writeText(visibleDiensten.join("\n"));
	};

	const getDisplayContent = (dienst, column) => {
		const content = column.getDisplayContent?.(
			dienst,
			familiesById,
			knownNanniesPerFamily,
			reactions,
			nanniesById
		);

		// console.log(content);

		return content;
	};

	return (
		<div className={styles.tableContainer}>
			<Table
				collapsing={false}
				className={styles.table}
				selectable={!!openAddEditModal}
				sortable
				celled
			>
				<Table.Header>
					<Table.Row>
						{columns.map((column) => (
							<Table.HeaderCell
								key={column.name}
								onClick={onSortClick(column.name)}
								collapsing={column.collapsing}
							>
								{column.label}
								{sort === column.name &&
									(asc ? <Icon name="caret up" /> : <Icon name="caret down" />)}
							</Table.HeaderCell>
						))}
						{deletable && (
							<Table.HeaderCell
								collapsing
								className={styles.iconCell}
								onClick={copyVisibleDiensten}
							>
								<Icon name="copy" />
							</Table.HeaderCell>
						)}
					</Table.Row>
					<Table.Row>
						{columns.map((column) => (
							<Table.HeaderCell
								key={column.name}
								className={styles.searchCell}
								collapsing={column.collapsing}
							>
								{column.disableSearch ? null : column.customFilterComponent ? (
									column.customFilterComponent(
										search[column.name],
										handleSearchChange(column.name)
									)
								) : (
									<Input
										key={column.name}
										className={styles.searchInput}
										placeholder={`Zoek...`}
										onChange={handleSearchChange(column.name)}
										value={search[column.name] || ""}
										icon="search"
										iconPosition="right"
										fluid
									/>
								)}
							</Table.HeaderCell>
						))}
						{deletable && <Table.HeaderCell collapsing />}
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{sortedDiensten.map((dienst) => (
						<Table.Row
							key={dienst.id + dienst.startTime + dienst.endTime}
							onClick={() => openAddEditModal?.(dienst.id)}
							style={{ cursor: "pointer" }}
						>
							{columns.map((column) => (
								<Table.Cell key={column.name} collapsing={column.collapsing}>
									{getDisplayContent(dienst, column)}
								</Table.Cell>
							))}
							{deletable && (
								<Table.Cell
									collapsing
									className={styles.iconCell}
									onClick={(evt) => {
										evt.stopPropagation();
										openDeleteModal?.(dienst.id);
									}}
								>
									<Icon name="trash" />
								</Table.Cell>
							)}
						</Table.Row>
					))}
				</Table.Body>
			</Table>
		</div>
	);
};
