import moment from "moment";

import firebase from "firebase";
import "firebase/firestore";

import { readFile } from "../../reader";
import { amountTypes, CreditStatus, tariffTypes, CREDITNRMONTHSVALID } from "../../constants";

const COLUMNIDX = {
	DATE: 0,
	DAYOFWEEK: 1,
	NANNYID: 2,
	NANNYNAME: 3,
	CUSTOMERID: 4,
	FAMILY: 5,
	LASTMINUTE: 6,
	NEXTINVOICE: 7,
	REGISTRATIONFEE: 8,
	COMMENTS: 9,
	CONFIRMED: 10,
	ENTERDATE: 11,
	LAST: 12
};

const columns = [
	{ index: COLUMNIDX.DATE, required: false, upper: false },
	{ index: COLUMNIDX.DAYOFWEEK, required: false, upper: false },
	{ index: COLUMNIDX.NANNYID, required: false, upper: false },
	{ index: COLUMNIDX.NANNYNAME, required: false, upper: false },
	{ index: COLUMNIDX.CUSTOMERID, required: true, upper: true },
	{ index: COLUMNIDX.FAMILY, required: false, upper: false },
	{ index: COLUMNIDX.LASTMINUTE, required: false, upper: false },
	{ index: COLUMNIDX.NEXTINVOICE, required: false, upper: false },
	{ index: COLUMNIDX.REGISTRATIONFEE, required: false, upper: false },
	{ index: COLUMNIDX.COMMENTS, required: false, upper: false },
	{ index: COLUMNIDX.CONFIRMED, required: false, upper: false },
	{ index: COLUMNIDX.ENTERDATE, required: false, upper: false }
];

const getSubscriptionForDate = (date, subscriptions, tariffs) => {
	if (!date) return null;
	if (!subscriptions) return null;

	let firstValid = null;
	for (let s = 0; s < subscriptions.length; s++) {
		if (subscriptions[s].tariffId < 0 || subscriptions[s].tariffId >= tariffs.length) {
			console.log("Invalid tarrifId: " + subscriptions[s].tariffId);
			continue;
		}

		const startDateStr = subscriptions[s].startDate;
		if (startDateStr && startDateStr !== "") {
			const startDate = moment(subscriptions[s].startDate, [
				"DD-MM-YY",
				"DD-MM-YYYY",
				"YYYY-MM-DD"
			]);
			if (startDate.isAfter(date)) continue;
		}

		const endDateStr = subscriptions[s].endDate;
		if (endDateStr && endDateStr !== "") {
			const endDate = moment(subscriptions[s].endDate, [
				"DD-MM-YY",
				"DD-MM-YYYY",
				"YYYY-MM-DD"
			]);
			if (endDate.isBefore(date)) continue;
		}

		if (!firstValid) {
			firstValid = subscriptions[s];
		}

		// For fixed, check if this is also really a fixed day
		if (tariffs[subscriptions[s].tariffId].type === tariffTypes.Fixed) {
			const weekDay = date.isoWeekday() - 1;
			if (!subscriptions[s].days[weekDay]) continue;
		}

		return subscriptions[s];
	}

	return firstValid;
};

const getTariffForDate = (date, subscriptions, tariffs) => {
	const subscription = getSubscriptionForDate(date, subscriptions, tariffs);
	if (subscription) return tariffs[subscription.tariffId];

	// No valid subscription, take last subscription if there are coming or past subscriptions
	let useTariffId = -1;
	if (subscriptions?.length > 0) {
		useTariffId = subscriptions[subscriptions.length - 1].tariffId;
	}

	// No subscription at all, fall back to last Flex tariff
	if (useTariffId < 0) {
		for (let t = tariffs.length - 1; t >= 0; t--) {
			if (tariffs[t].type !== tariffTypes.Flex) continue;
			useTariffId = t;
			break;
		}
	}

	let amounts = [0, 0, 14.95, 19.95, 69.95];
	if (useTariffId >= 0) {
		amounts = tariffs[useTariffId].amounts;
	}

	const tariff = {
		type: tariffTypes.Old,
		label: "Geen abonnement - enkel tarief",
		amounts
	};

	return tariff;
};

function onlyUnique(value, index, self) {
	return self.indexOf(value) === index;
}

const sortOnDateStr = (a, b) => {
	if (!a.dateStr && b.dateStr) return -1;
	if (!b.dateStr && a.dateStr) return 1;
	if (a.dateStr < b.dateStr) return -1;
	if (a.dateStr > b.dateStr) return 1;
	return 0;
};

const DATEFORMATFRIENDLY = "DD-MM-YYYY";
const getFriendlyDateFromDateStr = (dateStr) =>
	moment(dateStr, DATEFORMAT).format(DATEFORMATFRIENDLY);

const DATEFORMAT = "YYYY-MM-DD";
const addNewCreditsForMonth = (
	monthMoment,
	subscriptions,
	tariffs,
	credits,
	flexSubscriptions,
	onlyFamilies
) => {
	const lastDate = monthMoment.clone().endOf("month");
	const lastMonthDateStr = lastDate.format(DATEFORMAT);
	const firstMonthDateStr = monthMoment.clone().startOf("month").format(DATEFORMAT);

	if (credits) {
		// Remove credits for this month if any
		for (const familyId in credits) {
			if (onlyFamilies.length > 0 && !onlyFamilies.includes(familyId)) {
				// SKIP
				continue;
			}

			let creditsAfterRemove = [];
			for (let i = 0; i < credits[familyId].length; i++) {
				const { dateStr, usedOn } = credits[familyId][i];
				if (dateStr >= firstMonthDateStr && dateStr <= lastMonthDateStr) {
					// Credit for this month was already added in previous run, remove (will be added again later)
					continue;
				}

				if (usedOn && usedOn >= firstMonthDateStr && usedOn <= lastMonthDateStr) {
					creditsAfterRemove.push({ ...credits[familyId][i], usedOn: null });
				} else {
					creditsAfterRemove.push(credits[familyId][i]);
				}
			}
			credits[familyId] = creditsAfterRemove;
		}
	}

	for (const familyId in subscriptions) {
		if (onlyFamilies.length > 0 && !onlyFamilies.includes(familyId)) {
			// SKIP
			continue;
		}

		for (
			let curDate = monthMoment.clone().date(1);
			curDate.isSameOrBefore(lastDate);
			curDate.add(1, "day")
		) {
			const subscription = getSubscriptionForDate(
				curDate,
				subscriptions[familyId].subscriptions,
				tariffs
			);
			if (!subscription) continue;

			const tariff = tariffs[subscription.tariffId];
			if (!tariff) continue;

			if (tariff.type === tariffTypes.Flex) {
				if (!(familyId in flexSubscriptions)) {
					flexSubscriptions[familyId] = {
						tariffId: subscription.tariffId
					};
				}
			}

			if (tariff.type !== tariffTypes.Fixed) {
				continue;
			}

			// Only for fixed subscriptions
			const weekDay = curDate.isoWeekday() - 1;
			if (!subscription.days[weekDay]) continue;

			// Switch from flex to fixed!
			if (familyId in flexSubscriptions) {
				delete flexSubscriptions[familyId];
			}

			if (!(familyId in credits)) {
				credits[familyId] = [];
			}

			const dateStr = curDate.format(DATEFORMAT);
			const record = {
				dateStr: dateStr,
				validUntilDateStr: curDate
					.clone()
					.add(CREDITNRMONTHSVALID, "month")
					.format(DATEFORMAT),
				tariffId: subscription.tariffId,
				status: CreditStatus.Available
			};

			credits[familyId].push(record);
		}
	}

	// Make unique and sort chronologically
	for (const familyId in credits) {
		credits[familyId].sort(sortOnDateStr);
	}

	return credits;
};

const getInvoiceForFamily = (invoices, familyId, addifnotfound) => {
	if (addifnotfound) {
		if (!(familyId in invoices)) {
			invoices[familyId] = {
				customerId: familyId,
				lines: [],
				totalAmount: 0
			};
		}
	}

	return invoices[familyId];
};

export const importInvoiceData = (file, month, tariffs, credits, subscriptions, onlyFamilies) => {
	return new Promise((resolve, reject) => {
		var reader = new FileReader();
		reader.onload = async function (progressEvent) {
			const monthMoment = moment(month, "YYYY_MM");

			let invoices = {};
			const success = readFile(this.result, columns, reject, (lineArray) => {
				let familyId = lineArray[COLUMNIDX.CUSTOMERID];
				if (familyId.substring(0, 2) !== "AN") {
					console.log("FamilyId incorrect: " + familyId);
					return;
				}

				if (onlyFamilies.length > 0 && !onlyFamilies.includes(familyId)) {
					return;
				}

				if (
					lineArray[COLUMNIDX.REGISTRATIONFEE] &&
					lineArray[COLUMNIDX.REGISTRATIONFEE] !== ""
				) {
					if (tariffs && tariffs.length > 0) {
						const tariff = tariffs[tariffs.length - 1];
						const invoice = getInvoiceForFamily(invoices, familyId, true);
						invoice.lines.push({
							amount: tariff.amounts[amountTypes.RegistrationFee],
							description: "Inschrijfkosten"
						});
						console.log("Registration fee added: " + familyId);
					}
				}

				const date = moment(lineArray[COLUMNIDX.DATE], [
					"DD-MM-YY",
					"DD-MM-YYYY",
					"YYYY-MM-DD"
				]);
				if (!date.isValid()) {
					console.log("Date incorrect: " + lineArray[COLUMNIDX.DATE]);
					return;
				}

				const line = {
					dateStr: date.format(DATEFORMAT)
				};

				if (lineArray[COLUMNIDX.LASTMINUTE] && lineArray[COLUMNIDX.LASTMINUTE] !== "") {
					line.lastMinute = true;
				}

				const invoice = getInvoiceForFamily(invoices, familyId, true);
				invoice.lines.push(line);
			});

			if (!success) {
				reject("!success in importInvoiceData");
				return;
			}

			const lastMonthDateStr = monthMoment.clone().endOf("month").format(DATEFORMAT);
			const firstMonthDateStr = monthMoment.clone().startOf("month").format(DATEFORMAT);

			let flexSubscriptions = {};
			const newCredits = JSON.parse(JSON.stringify(credits));
			addNewCreditsForMonth(
				monthMoment,
				subscriptions,
				tariffs,
				newCredits,
				flexSubscriptions,
				onlyFamilies
			);

			const allFamilies = [
				...Object.keys(invoices),
				...Object.keys(newCredits),
				...Object.keys(flexSubscriptions)
			].filter(onlyUnique);
			for (let i = 0; i < allFamilies.length; i++) {
				const familyId = allFamilies[i];
				if (familyId.substring(0, 2) !== "AN") {
					console.log("FamilyId incorrect: " + familyId);
					continue;
				}

				if (onlyFamilies.length > 0 && !onlyFamilies.includes(familyId)) {
					// SKIP
					continue;
				}

				const invoice = getInvoiceForFamily(invoices, familyId, false);
				if (invoice) {
					// Ensure chronologically sorted invoice lines (important for credit use)
					invoice.lines.sort(sortOnDateStr);

					for (let linenr = 0; linenr < invoice.lines.length; linenr++) {
						const line = invoice.lines[linenr];
						if (!line.dateStr) continue;

						const date = moment(line.dateStr, DATEFORMAT);

						const tariff = getTariffForDate(
							date,
							subscriptions[familyId] ? subscriptions[familyId].subscriptions : null,
							tariffs
						);
						if (!tariff) continue;

						let withinFlex = false;
						if (tariff.type === tariffTypes.Flex) {
							if (
								familyId in flexSubscriptions &&
								!flexSubscriptions[familyId].usedOn
							) {
								withinFlex = true;
								flexSubscriptions[familyId].usedOn = line.dateStr;
							}
						} else if (tariff.type === tariffTypes.Fixed) {
							if (!newCredits[familyId]) {
								console.log("Tariff fixed but no days: " + familyId);
								invoice.lines = [];
								invoice.error = "Vast tarief zonder dagen";
								continue;
							}
						}

						// Check credits to see if fixed day or valid credit left
						let fixedDay = -1;
						let credit = -1;
						if (newCredits[familyId]) {
							for (let c = 0; c < newCredits[familyId].length; c++) {
								const { dateStr, validUntilDateStr, status } =
									newCredits[familyId][c];
								if (dateStr > line.dateStr) break;
								if (validUntilDateStr < line.dateStr) continue;
								if (status !== CreditStatus.Available) continue;

								if (credit < 0) {
									credit = c;
								}

								if (newCredits[familyId][c].dateStr === line.dateStr) {
									fixedDay = c;
								}
							}
						}

						if (line.lastMinute) {
							// Last minute
							line.amount = tariff.amounts[amountTypes.LastMinute];
							line.description = "Last-minute dienst";
						} else if (withinFlex) {
							// Flex subscription
							line.amount = tariff.amounts[amountTypes.SubscriptionCost];
							line.description = `Dienst flex-abonnement ${monthMoment.format(
								"MMMM"
							)}`;
						} else if (fixedDay >= 0) {
							// Fixed day
							line.amount = tariff.amounts[amountTypes.PerFixedDay];
							line.description = "Vaste dienst";

							// Remove from credits
							newCredits[familyId].splice(fixedDay, 1);
						} else if (credit >= 0) {
							// Use old, valid credit
							line.amount = 0;
							line.description = `Enkele dienst, tegoed gebruikt van ${getFriendlyDateFromDateStr(
								newCredits[familyId][credit].dateStr
							)}`;

							newCredits[familyId][credit].status = CreditStatus.Used;
							newCredits[familyId][credit].usedOn = line.dateStr;
						} else {
							// No credit left, pay up
							line.amount = tariff.amounts[amountTypes.Single];
							line.description = "Enkele dienst";
						}

						line.date = date.format(DATEFORMATFRIENDLY);
						line.dayOfWeek = date.format("dddd");
					}
				}

				// Pay for remaining new credits that are not used
				if (newCredits[familyId]) {
					for (let c = 0; c < newCredits[familyId].length; c++) {
						const { tariffId, validUntilDateStr, dateStr } = newCredits[familyId][c];
						if (dateStr < firstMonthDateStr) continue; // Before this month
						if (dateStr > lastMonthDateStr) continue; // After this month

						const tariff = tariffs[tariffId];
						if (!tariff) continue;

						const date = moment(dateStr, DATEFORMAT);
						const line = {
							dateStr,
							date: date.format(DATEFORMATFRIENDLY),
							dayOfWeek: date.format("dddd"),
							description: `Vaste dienst (niet opgenomen, tegoed geldig tot ${getFriendlyDateFromDateStr(
								validUntilDateStr
							)})`,
							amount: tariff.amounts[amountTypes.PerFixedDay]
						};

						const addToInvoice = getInvoiceForFamily(invoices, familyId, true);
						addToInvoice.lines.push(line);
					}

					// Set expired credits
					for (let c = 0; c < newCredits[familyId].length; c++) {
						const { validUntilDateStr, status } = newCredits[familyId][c];
						if (validUntilDateStr > lastMonthDateStr) break;
						if (status === CreditStatus.Used) continue;

						newCredits[familyId][c].status = CreditStatus.Expired;
					}
				}

				// Pay for unused flex days
				if (familyId in flexSubscriptions && !flexSubscriptions[familyId].usedOn) {
					const { tariffId } = flexSubscriptions[familyId];
					const tariff = tariffs[tariffId];

					const line = {
						description: `Dienst flex-abonnement ${monthMoment.format(
							"MMMM"
						)} (niet opgenomen)`,
						amount: tariff.amounts[amountTypes.SubscriptionCost]
					};

					const addToInvoice = getInvoiceForFamily(invoices, familyId, true);
					addToInvoice.lines.push(line);
				}
			}

			// Final sort on all invoice lines and calculate totalAmount
			for (const familyId in invoices) {
				if (!invoices[familyId].lines || invoices[familyId].lines.length === 0) {
					if (!invoices[familyId].error)
						invoices[familyId].error = "Geen regels voor factuur";
				}

				invoices[familyId].lines.sort(sortOnDateStr);
				invoices[familyId].totalAmount = invoices[familyId].lines.reduce(
					(partialSum, line) => {
						if (line.amount) return partialSum + line.amount;
						return partialSum;
					},
					0
				);
			}

			resolve({ invoices, newCredits });
		};

		reader.readAsText(file);
	});
};
