export const getSubscriptionDienstFromInfo = (subscriptionDiensten, dienstInfo, errors) => {
	let filteredSubscriptionDiensten = subscriptionDiensten.filter(
		(d) => d.familyId === dienstInfo.familyId && d.date === dienstInfo.date
	);

	if (filteredSubscriptionDiensten.length === 0) {
		const id = `${dienstInfo.familyId}_${dienstInfo.date}_${dienstInfo.nannyId}_${dienstInfo.startTime}_${dienstInfo.endTime}`;
		errors[id] = dienstInfo;
		return null;
	}

	let dienst;
	if (filteredSubscriptionDiensten.length > 1) {
		let matchOnKeys = ["nannyId", "startTime", "endTime"];

		let maxMatchingKeys = 0;
		for (let d = 0; d < filteredSubscriptionDiensten.length; d++) {
			const subscriptionDienst = filteredSubscriptionDiensten[d];

			let matchingKeys = 0;
			for (let j = 0; j < matchOnKeys.length; j++) {
				let key = matchOnKeys[j];
				if (subscriptionDienst[key] === dienstInfo[key]) {
					matchingKeys++;
				}
			}

			if (matchingKeys > maxMatchingKeys) {
				maxMatchingKeys = matchingKeys;
				dienst = subscriptionDienst;
			}

			if (maxMatchingKeys === matchOnKeys.length) {
				break;
			}
		}

		if (!dienst) {
			console.log("Could not find a perfect match", dienstInfo, filteredSubscriptionDiensten);
		}
	} else {
		dienst = filteredSubscriptionDiensten[0];
	}

	return dienst;
};
