import moment from "moment";

export const checkDienstenMonth = (diensten, fromMonthStr, toMonthStr) => {
	// Check month
	if (!diensten) return false;
	
	if (!diensten.length) {
		// empty is allowed
		return true;
	}

	let firstDienst = diensten[0];
	let dateMonth = moment(firstDienst.date, "YYYY-MM-DD").format("YYYY_MM");
	if (dateMonth < fromMonthStr || dateMonth > toMonthStr) {
		console.error("First dienst month does not match fromMonthStr", fromMonthStr, dateMonth);
		return false;
	}

	return true;
};
