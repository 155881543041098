import React from "react";
import styles from "./index.module.css";
import { DienstenTable } from "../DienstenTable";
import { useReactionsListener } from "../../hooks/useReactions";
import { Button, Dimmer, Form, Icon, Loader } from "semantic-ui-react";
import { useModal } from "../../hooks/useModal";
import { AddEditModal } from "./AddEditModal";
import { useFamiliesById } from "../../hooks/useFamilies";
import { useKnownNannies } from "../../hooks/useKnownNannies";
import MonthDropdown from "../MonthDropdown";
import moment from "moment";
import { CancelDienstButton } from "../CancelDienstButton";
import { DeleteModal } from "./DeleteModal";
import { useActualDiensten } from "../../hooks/useActualDiensten";

export const DienstenTab = () => {
	const [month, setMonth] = React.useState(moment().date(1).format("YYYY_MM"));

	const { actualDiensten, loading: loadingDiensten } = useActualDiensten({
		familyId: null,
		fromMonthStr: month,
		toMonthStr: month,
		includeReplaced: false,
		includeDiensten: true,
		includeSubscriptionDiensten: false,
		onlyLocalChanges: false,
		includeCancelled: true
	});
	const { reactions, loading: loadingReactions } = useReactionsListener();

	const familiesById = useFamiliesById();
	const knownNanniesPerFamily = useKnownNannies();

	const { modalProps: addEditModalProps, openModal: openAddEditModal } = useModal(actualDiensten);
	const { modalProps: deleteModalProps, openModal: openDeleteModal } = useModal(actualDiensten);

	const loading = loadingDiensten || loadingReactions || !familiesById || !knownNanniesPerFamily;

	return (
		<div className={styles.container}>
			{loading && (
				<Dimmer active>
					<Loader />
				</Dimmer>
			)}
			<AddEditModal
				{...addEditModalProps}
				knownNanniesPerFamily={knownNanniesPerFamily}
				familiesById={familiesById}
				reactions={reactions}
			/>
			<DeleteModal
				{...deleteModalProps}
				knownNanniesPerFamily={knownNanniesPerFamily}
				familiesById={familiesById}
				reactions={reactions}
			/>
			<div className={styles.headerContainer}>
				<div className={styles.selectMonthContainer}>
					<Form>
						<Form.Group inline style={{ marginBottom: 0 }}>
							<MonthDropdown
								fluid={false}
								month={month}
								setMonth={setMonth}
								monthsAhead={12}
							/>
						</Form.Group>
					</Form>
				</div>
				<div style={{ display: "flex" }}>
					<CancelDienstButton />
					<Button
						icon
						loading={loading}
						labelPosition="right"
						onClick={() => openAddEditModal(null)}
					>
						Nieuwe dienst
						<Icon name="add" />
					</Button>
				</div>
			</div>
			<DienstenTable
				diensten={actualDiensten}
				reactions={reactions}
				openAddEditModal={openAddEditModal}
				openDeleteModal={openDeleteModal}
			/>
		</div>
	);
};
