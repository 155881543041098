import React from "react";
import moment from "moment";

import { Icon, Button, Header, Segment, Progress, Message, Form } from "semantic-ui-react";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

import MonthDropdown from "../MonthDropdown";
import { FileImportFormControlled } from "../FileImportForm";

import Navbar from "../Navbar";
import styles from "./NewInvoice.module.css";

import { importInvoiceData } from "./importInvoiceData";
import { useFamilies } from "../../hooks/useFamilies";
import { useTariffs } from "../../hooks/useTariffs";
import { useCredits } from "../../hooks/useCredits";
import { useSubscriptions } from "../../hooks/useSubscriptions";

import NewInvoiceTable from "./NewInvoiceTable";
import { FamilyDropdown } from "../FamilyDropdown";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const NewInvoice = () => {
	const families = useFamilies();
	const familiesByID = families.reduce((obj, item) => ((obj[item.id] = item), obj), {});
	const credits = useCredits();

	const tariffs = useTariffs();
	const subscriptions = useSubscriptions();

	const [month, setMonth] = React.useState(
		moment().date(1).subtract(1, "month").format("YYYY_MM")
	);

	const [allFamilies, setAllFamilies] = React.useState(true);
	const [selectedFamilies, setSelectedFamilies] = React.useState([]);

	const [invoiceData, setInvoiceData] = React.useState({});
	const [uploading, setUploading] = React.useState("idle");
	const [uploadProgress, setUploadProgress] = React.useState(0);
	const [uploadErrors, setUploadErrors] = React.useState([]);

	const [newCredits, setNewCredits] = React.useState({});

	const [file, setFile] = React.useState(null);
	const [fileState, setFileState] = React.useState("idle");
	const [fileError, setFileError] = React.useState("");

	const handleFileImport = (file) => {
		setFileState("loading");
		importInvoiceData(
			file,
			month,
			tariffs,
			credits,
			subscriptions,
			allFamilies ? [] : selectedFamilies
		)
			.then(({ invoices, newCredits }) => {
				setFileState("success");
				setInvoiceData(invoices);
				setNewCredits(newCredits);
			})
			.catch((err) => {
				console.log(err);
				setFileState("error");
				setFileError(err);
			});
	};

	const loading = !families || !tariffs || !subscriptions;

	let totalAmount = 0;
	if (invoiceData) {
		for (const familyId in invoiceData) {
			if (!invoiceData[familyId].totalAmount) continue;
			totalAmount = totalAmount + invoiceData[familyId].totalAmount;
		}
	}

	const handleMonthChange = (month) => {
		setInvoiceData({});
		setFileState("idle");
		setFileError("");
		setFile(null);
		setMonth(month);
	};

	const uploadToMoneybird = async () => {
		if (!invoiceData) return;

		setUploading("loading");
		setUploadProgress(0);
		setUploadErrors([]);

		var createInvoice = firebase.functions().httpsCallable("createInvoice");

		for (const familyId in invoiceData) {
			if (!invoiceData[familyId].error) {
				let name = "Naam onbekend";
				if (familiesByID[familyId]) {
					name = familiesByID[familyId].name;
				} else if (subscriptions[familyId]) {
					name = subscriptions[familyId].name;
				}

				try {
					const invoiceResult = await createInvoice({
						...invoiceData[familyId],
						customerName: name
					});

					console.log(invoiceResult);
				} catch (err) {
					console.log(err);
					setUploadErrors((current) => [...current, { familyId, error: err }]);
				}
			}

			setUploadProgress((current) => current + 1);
			await delay(7000);
		}

		const allPromises = [];
		if (newCredits) {
			for (const familyId in newCredits) {
				if (!allFamilies && !selectedFamilies.includes(familyId)) {
					// SKIP
					continue;
				}

				const promise = firebase
					.firestore()
					.collection("credits")
					.doc(familyId)
					.set({ credits: newCredits[familyId] });

				allPromises.push(promise);
			}
		}

		Promise.all(allPromises)
			.then(() => {
				console.log("All invoices and credits uploaded");
				setUploading("success");
			})
			.catch((err) => {
				console.log(err);
				setUploading("error");
			});
	};

	return (
		<div className={styles.container}>
			<Navbar />
			<div className={styles.content}>
				{uploading === "success" || (
					<Form>
						<Segment loading={loading || uploading === "loading"}>
							<Header>1. Kies maand</Header>
							<MonthDropdown
								month={month}
								setMonth={handleMonthChange}
								monthsAhead={-1}
							/>
						</Segment>
						<Segment loading={loading || uploading === "loading"}>
							<Header>2. Kies gezinnen</Header>
							<Form.Checkbox
								label="Alle gezinnen"
								checked={allFamilies}
								onChange={(_, { checked }) => setAllFamilies(checked)}
								toggle
							/>

							{!allFamilies && (
								<FamilyDropdown
									label="Gezinnen"
									value={selectedFamilies}
									onChange={(e, { value }) => setSelectedFamilies(value)}
									multiple
									search
									selection
									fluid
									clearable
								/>
							)}
						</Segment>
						<Segment loading={loading || uploading === "loading"}>
							<Header style={{ margin: 0 }}>3. Importeer bestand</Header>
							<FileImportFormControlled
								name="Factuurdata"
								file={file}
								setFile={setFile}
								loading={fileState === "loading"}
								error={fileState === "error"}
								errorMessage={fileError}
								success={fileState === "success"}
								onSubmit={handleFileImport}
								basic
								noHeader
							/>
						</Segment>
					</Form>
				)}
				<Segment loading={loading || uploading === "loading"}>
					<Header>4. Controleer</Header>
					<NewInvoiceTable
						invoiceData={invoiceData}
						familiesByID={familiesByID}
						totalAmount={totalAmount}
						subscriptions={subscriptions}
					/>
				</Segment>
				{uploading === "loading" && (
					<Progress
						value={uploadProgress}
						total={Object.keys(invoiceData).length}
						progress="ratio"
						indicating
					>
						Uploaden naar moneybird... Dit zal even duren, houd je tabblad open...
					</Progress>
				)}
				{uploading === "success" && (
					<Message success header="Facturen en tegoed geupload!" />
				)}
				{uploading === "idle" && (
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<Button
							icon
							labelPosition="right"
							positive
							loading={loading}
							disabled={totalAmount < 20}
							onClick={uploadToMoneybird}
						>
							Upload facturen naar Moneybird
							<Icon name="upload" />
						</Button>
					</div>
				)}
				{uploadErrors.length > 0 && (
					<Message negative>
						<Message.Header>
							Er zijn fouten opgetreden bij het uploaden van de facturen
						</Message.Header>
						<Message.List>
							{uploadErrors.map(({ familyId, error }) => (
								<Message.Item key={familyId}>
									{familyId} - {familiesByID[familyId]?.name} - {error.message}
								</Message.Item>
							))}
						</Message.List>
					</Message>
				)}
			</div>
		</div>
	);
};

export default NewInvoice;
